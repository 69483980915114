import React from 'react';
import PropTypes from 'prop-types';

import { Paragraph } from '../../styles/commonStyles';
import { About, StyledButton } from './RoomView.style';
import Container from '../Container/Container';

const propTypes = {
  content: PropTypes.string,
  link: PropTypes.string,
};

const defaultProps = {
  content: '',
  link: '',
};

const RoomView = ({ content, link }) => (
  <Container justifyCenterTablet padding>
    <About>
      <Paragraph>{content && content}</Paragraph>
      <a href={link} rel="noopener noreferrer" target="_blank" aria-label="Link do booking.com">
        <StyledButton>BOOKUJ</StyledButton>
      </a>
    </About>
  </Container>
);

RoomView.propTypes = propTypes;
RoomView.defaultProps = defaultProps;

export default RoomView;
