import Styled from 'styled-components';

import { breakpoints } from '../../styles/variables';
import Button from '../Button/Button';

export const About = Styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 90%;

  @media ${breakpoints.tabletLandscape} {
    max-width: 60%;
  }
`;

export const StyledButton = Styled(Button)`
  margin: 2rem 0;
`;
