/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import Hero from '../../components/Hero/Hero';
import RoomView from '../../components/RoomView/RoomView';

import RoomQuery from './room.query';

const propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

const Room = ({ pageContext }) => {
  const { id } = pageContext;

  return (
    <RoomQuery id={id}>
      {({ node }) => {
        const {
          apartmentSliderHeading,
          apartmentSliderImages,
          apartmentDescription,
          apartmentLink,
        } = node.acf;

        return (
          <>
            <Hero heading={apartmentSliderHeading} slides={apartmentSliderImages} />
            <RoomView
              content={apartmentDescription}
              image={apartmentSliderImages}
              link={apartmentLink}
            />
          </>
        );
      }}
    </RoomQuery>
  );
};

Room.propTypes = propTypes;

export default Room;
