import React from 'react';
import PropTypes from 'prop-types';

import { StaticQuery, graphql } from 'gatsby';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func])
    .isRequired,
  id: PropTypes.string.isRequired,
};

const RoomQuery = ({ children, id }) => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPage {
          edges {
            node {
              id
              title
              acf {
                apartmentDescription
                apartmentSliderHeading
                apartmentLink
                apartmentSliderImages {
                  image {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 900) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ allWordpressPage }) =>
      children(allWordpressPage.edges.find(({ node }) => node.id === id || null))
    }
  />
);

RoomQuery.propTypes = propTypes;

export default RoomQuery;
